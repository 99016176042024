<template>
  <div class="box sc-width">
<!--    <div class="main-title-box">-->
<!--      <h1 class="main-title">Sample Requests</h1>-->
<!--    </div>-->

    <div class="pd-20 cards-p" style="">
      <div class="cards">
        <Spin v-if="loading" fix></Spin>

        <div>
          <div class="list-table">
            <div class="title-top"></div>
            <div class="title">
              <div class="item product font-medium">Product Name</div>
              <div class="item attendee font-medium">Attendee</div>
              <!--              <div class="item factory">Factory Name</div>-->
              <div class="item size font-medium">Sample Size</div>
              <div class="item date font-medium">Request Date</div>
              <div class="item document font-medium">Documents</div>
            </div>
            <div class="content">
              <div
                v-for="(item, index) in list"
                :key="index"
                class="item"
              >
                <div class="top">
                  <div class="product">
                    <div class="main-name font-medium">
                      {{ item.product.marketing_name }}
                    </div>
                    <div class="by-name">
                      By {{ item.sample_company.company_name }}
                    </div>
                  </div>
                  <div class="attendee font-medium">
                    {{ item.company.company_name }}
                  </div>
                  <!--                  <div class="factory"></div>-->
                  <div class="size font-medium">{{ item.sample_size }}</div>
                  <div class="date font-medium">
                    {{ item.created_at | shortTime }}
                  </div>
                  <div class="document">
                    <template v-if="item.sample_request_documents.length"
                      >-->
                      <p
                        class="tip doc"
                        v-for="(doc, index) in item.sample_request_documents"
                        :key="index"
                      >
                        {{ doc.name }}
                        <a :href="doc.url" target="_blank"
                          ><Icon type="md-open"
                        /></a>
                      </p>
                    </template>
                  </div>
                </div>
                <div class="divided"></div>
                <div class="bottom">
                  <div class="left">
                    <div class="tracking" style="display: flex">
                      <div class="status">
                        <template>
                          <p class="status pend " v-if="item.state === 0">
                            Pending Response
                          </p>
                          <p class="status acc" v-else-if="item.state === 1">
                            Request Accepted
                          </p>
                          <p class="status rej" v-else-if="item.state === -1">
                            Request Rejected
                          </p>
                        </template>
                      </div>
                      <div
                        class="footer"
                        style="text-align:left;position: relative;bottom: 4px;"
                      >
                        <div class="numb font-medium" v-if="item.state === 1">
                          <Icon
                            type="ios-alert-outline"
                            style="font-size:16px;color:#000"
                          />
                          <strong>Tracking Number:</strong>
                          <span style="padding-left:4px;color:#888">{{
                            item.tracking_number
                          }}</span>
                          <Icon
                            @click="editNumberFunc(item)"
                            type="md-create"
                            style="cursor:pointer;margin-left:20px;font-size:18px;color:#ff6600"
                            v-if="role === 'exhibitor'"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="browse font-medium"
                    @click="
                      $router.push(`/requests/${item.id}?from=requestlists`)
                    "
                  >
                    Enter to browse >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty" v-if="list.length === 0">
            No Data
          </div>
          <div class="page">
            <Page
              transfer
              :page-size="limit"
              :total="total"
              show-elevator
              show-total
              @on-change="pageChange"
              @on-page-size-change="pageSizeChange"
            />
          </div>
        </div>

        <Modal v-model="editNumberPop" footer-hide title="TRACKING NUMBER">
          <Input
            style="width:100%;font-size:12px;"
            placeholder="Please fill tracking number..."
            v-model="editForm.trackNumber"
          />
          <div style="padding-top:15px;text-align:right">
            <Button
              size="small"
              type="primary"
              style="color:#f95858;background:transparent;border:1px solid #f95858"
              @click="(editNumberPop = false), (editForm.trackNumber = '')"
              >Cancel</Button
            >
            &nbsp;&nbsp;
            <Button
              size="small"
              type="primary"
              :loading="editLoading"
              @click="takeNumber('-99')"
              >Confirm</Button
            >
          </div>
        </Modal>

        <Modal v-model="rejectPop" footer-hide title="Reason for Rejecting">
          <Input
            :rows="5"
            type="textarea"
            placeholder="Please state the reason why you are rejecting this request..."
            v-model="samForm.reject_reason"
          />
          <div style="padding-top:15px;text-align:right">
            <Button
              size="small"
              type="primary"
              style="color:#f95858;background:transparent;border:1px solid #f95858"
              @click="(rejectPop = false), (samForm.reject_reason = '')"
              >Cancel</Button
            >
            &nbsp;&nbsp;
            <Button
              size="small"
              type="primary"
              :loading="confLoading"
              @click="sendReject"
              >Confirm</Button
            >
          </div>
        </Modal>

        <Modal v-model="acceptPop" footer-hide title="Accept Request">
          <h4 style="padding:10px 0;">Are you sure to accept it ?</h4>
          <div style="text-align:right">
            <Button type="primary" :loading="confLoading" @click="sendRejectAcc"
              >Confirm</Button
            >
          </div>
        </Modal>

        <Modal v-model="delPop" footer-hide title="Delete the Inquiry">
          <h4 style="padding:10px 0;">Are you sure to del it ?</h4>
          <div style="text-align:right">
            <Button type="primary" :loading="confLoading" @click="delConfirm"
              >Confirm</Button
            >
          </div>
        </Modal>
        <Modal
          class="sourcing"
          v-model="showInquire"
          title="Sourcing Inquiry"
          footer-hide
          @on-cancel="cancel"
          :styles="{ top: '10px' }"
        >
          <Spin v-if="confLoading" fix></Spin>
          <div class="content">
            <Form ref="form" :model="inquireForm">
              <Row :gutter="20">
                <i-col span="12">
                  <FormItem
                    label="PRODUCT NAME"
                    :rules="required"
                    prop="product_name"
                  >
                    <Input v-model="inquireForm.product_name" />
                  </FormItem>
                </i-col>
                <i-col span="12">
                  <FormItem label="PRODUCT TYPE">
                    <Select v-model="inquireForm.product_type">
                      <OptionGroup label="Botanical">
                        <Option value="Botanical">Botanical</Option>
                      </OptionGroup>

                      <OptionGroup label="Non-Botanical">
                        <Option
                          v-for="item in cateList['Non-Botanical']"
                          :key="item"
                          :value="item"
                          >{{ item }}</Option
                        >
                      </OptionGroup>
                    </Select>
                  </FormItem>
                </i-col>
                <i-col span="12">
                  <FormItem label="ORIGIN">
                    <Select filterable v-model="inquireForm.country">
                      <Option
                        v-for="item in countryList"
                        :value="item.id"
                        :key="item.id"
                        >{{ item.name }}</Option
                      >
                    </Select>
                  </FormItem>
                </i-col>
                <i-col span="12">
                  <FormItem label="STATE">
                    <Input v-model="inquireForm.state" />
                  </FormItem>
                </i-col>

                <i-col span="24">
                  <div class="form-box-group">
                    <div class="form-box-title">
                      INDUSTRY USAGE GRADE
                    </div>
                    <div class="compose">
                      <div class="checkbox-box">
                        <FormItem prop="industry_usage_grade">
                          <CheckboxGroup
                            class="mb-10"
                            v-model="inquireForm.industry_usage_grade"
                          >
                            <Row :gutter="20">
                              <i-col
                                v-for="item in industry_usage_grade"
                                :key="item"
                                span="12"
                              >
                                <Checkbox :label="item" />
                              </i-col>
                            </Row>
                          </CheckboxGroup>
                          <Input
                            v-model="industry_usage_grade_temp"
                            class="mr-10 checkbox-box-input"
                          />
                          <Button
                            @click="addCheckBox('industry_usage_grade')"
                            type="primary"
                            >Add Item</Button
                          >
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </i-col>

                <i-col span="24">
                  <div class="form-box-group">
                    <div class="form-box-title">
                      COMPENDIAL STANDARD
                    </div>
                    <div class="compose">
                      <div class="checkbox-box">
                        <FormItem prop="compendial_standard">
                          <CheckboxGroup
                            class="mb-10"
                            v-model="inquireForm.compendial_standard"
                          >
                            <Row :gutter="20">
                              <i-col
                                v-for="item in compendial_standard"
                                :key="item"
                                span="12"
                              >
                                <Checkbox :label="item" />
                              </i-col>
                            </Row>
                          </CheckboxGroup>
                          <Input
                            v-model="compendial_standard_temp"
                            class="mr-10 checkbox-box-input"
                          />
                          <Button
                            @click="addCheckBox('compendial_standard')"
                            type="primary"
                            >Add Item</Button
                          >
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </i-col>
              </Row>
            </Form>
          </div>
          <div
            class="btn"
            style="margin-top:15px;text-align: right;color: #f57c00;"
          >
            <Button
              style="width:120px;border: 1px solid #f57c00;background-color:#f57c00;color:#fff"
              @click="sendInquire"
              >Send Inquire</Button
            >
          </div>
        </Modal>
      </div>
    </div>
  </div>
  <!--  </div>-->
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
import { uniq, trim } from "lodash";
const {
  _queryProductRelatedInfo,
  _country,
  _querySampleList,
  _removeSourcing,
  _checkSample,
  _numberSample
} = api;

export default {
  name: "products",
  computed: {
    ...mapState("user", ["role"])
  },
  data() {
    return {
      rejectPop: false,
      curItemRejected: null,
      acceptPop: false,
      delPop: false,
      confLoading: false,
      numfLoading: false,
      showInquire: false,
      editLoading: false,
      editNumber: false,
      editNumberPop: false,
      editForm: {
        id: null,
        trackNumber: null
      },
      samForm: {
        state: null,
        reject_reason: null,
        tracking_number: null
      },
      inquireForm: {
        product_name: "-"
      },
      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],
      industry_usage_grade: [
        "Food Grade",
        "Feed Grade",
        "Industrial Grade",
        "Cosmetic Grade"
      ],
      industry_usage_grade_temp: "",
      compendial_standard: ["USP", "FCC", "JP", "CP"],
      compendial_standard_temp: "",
      curItem: {},
      modType: "card",
      page: 1,
      limit: 10, // must in 5-20
      total: 0,
      list: [],
      loading: false,
      sourcingCol: [
        {
          type: "index",
          width: 60,
          align: "center"
        },
        {
          title: "CUSTOMERS NAME",
          render: (h, params) => {
            return h(
              "span",
              params.row.user.first_name + "/" + params.row.user.last_name
            );
          }
        },
        {
          title: "COMPANY",
          render: (h, params) => {
            return h(
              "span",
              (params.row.company && params.row.company.company_name) || "--"
            );
          }
        },
        {
          title: "PRODUCT NAME",
          key: "product_name"
        },
        {
          title: "ORIGIN/STATE",
          render: (h, params) => {
            return h(
              "span",
              ((params.row.country_info && params.row.country_info.name) ||
                "--") +
                " " +
                (params.row.state || "")
            );
          }
        },
        {
          title: "PRODUCT TYPE",
          width: 150,
          render: (h, params) => {
            return h("span", params.row.product_type || "--");
          }
        },
        {
          title: "Action",
          width: 130,
          align: "center",
          slot: "action"
        }
      ],
      cateList: [],
      countryList: [],
      filter: {
        product_name: ""
      },
      activeIndex: null
    };
  },
  created() {
    this.getSamples();
    _country().then(response => {
      const { data } = response;
      this.countryList = data;
    });
    _queryProductRelatedInfo().then(response => {
      const { data } = response;
      this.cateList = data.product_type;
    });
  },

  methods: {
    companyMouseOver(e) {
      this.activeIndex = e;
    },
    companyMouseLeave() {
      this.activeIndex = null;
    },
    clearSearch() {
      this.page = 1;
      this.curBar = "";
      this.filter.product_name = "";
      this.getSamples();
    },

    handleSearch() {
      this.page = 1;
      this.getSamples();
    },

    pageChange(page) {
      this.page = page;
      this.getSamples();
    },

    pageSizeChange(limit) {
      this.limit = limit;
      this.getSamples();
    },
    getSamples() {
      this.loading = true;
      this.list = [];
      _querySampleList({
        page: this.page,
        limit: this.limit,
        ...this.filter
      })
        .then(response => {
          const { data } = response;
          this.list = data.data;

          data.data.forEach((item, index) => {
            // industry_usage_grade
            var copyIndustry = JSON.parse(
              JSON.stringify(this.industry_usage_grade)
            );
            if (item.industry_usage_grade) {
              var i = 0;
              item.industry_usage_grade.forEach(ite => {
                if (copyIndustry.indexOf(ite) < 0) {
                  i++;
                  copyIndustry.push(ite);
                  this.$set(
                    this.list[index],
                    "industry_usage_grade2",
                    copyIndustry
                  );
                }

                if (i === 0) {
                  this.$set(
                    this.list[index],
                    "industry_usage_grade2",
                    copyIndustry
                  );
                }
              });
            } else {
              this.$set(
                this.list[index],
                "industry_usage_grade2",
                copyIndustry
              );
            }

            // compendial_standard
            var copyCompendial = JSON.parse(
              JSON.stringify(this.compendial_standard)
            );
            if (item.compendial_standard) {
              var j = 0;
              item.compendial_standard.forEach(ite => {
                if (copyCompendial.indexOf(ite) < 0) {
                  j++;
                  copyCompendial.push(ite);
                  this.$set(
                    this.list[index],
                    "compendial_standard2",
                    copyCompendial
                  );
                }

                if (j === 0) {
                  this.$set(
                    this.list[index],
                    "compendial_standard2",
                    copyCompendial
                  );
                }
              });
            } else {
              this.$set(
                this.list[index],
                "compendial_standard2",
                copyCompendial
              );
            }
          });

          this.total = data.total;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    rejectDo(item) {
      this.rejectPop = true;
      this.curItemRejected = item;
    },
    acceptDo(item) {
      this.acceptPop = true;
      this.curItemRejected = item;
    },
    sendRejectAcc() {
      this.confLoading = true;
      _checkSample({
        id: this.curItemRejected.id,
        state: 1
      })
        .then(() => {
          this.$Message.success("Submit Success");
          this.acceptPop = false;
          this.getSamples();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.confLoading = false;
        });
    },
    sendReject() {
      if (!this.samForm.reject_reason) {
        this.$Message.warning("Please fill reason");
        return;
      }
      this.confLoading = true;
      _checkSample({
        id: this.curItemRejected.id,
        state: -1,
        reject_reason: this.samForm.reject_reason
      })
        .then(() => {
          this.$Message.success("Submit Success");
          this.rejectPop = false;
          this.samForm.reject_reason = "";
          this.getSamples();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.confLoading = false;
        });
    },
    takeNumber(curid) {
      if (!this.samForm.tracking_number && !this.editForm.trackNumber) {
        this.$Message.warning("Please fill it!");
        return;
      }
      var curDatas = {
        id: curid,
        tracking_number: this.samForm.tracking_number
      };
      if (curid === "-99") {
        if (!this.editForm.trackNumber) {
          this.$Message.warning("Please fill it!");
          return;
        }
        this.editLoading = true;
        curDatas = {
          id: this.editForm.id,
          tracking_number: this.editForm.trackNumber
        };
      } else {
        if (!this.samForm.tracking_number) {
          this.$Message.warning("Please fill it!");
          return;
        }
        this.numfLoading = true;
      }

      _numberSample(curDatas)
        .then(() => {
          this.$Message.success("Submit Success");
          this.samForm.tracking_number = "";
          this.editNumber = false;
          if (curid === "-99") {
            this.editLoading = false;
            this.editNumberPop = false;
          }
          this.getSamples();
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.numfLoading = false;
          this.editLoading = false;
        });
    },
    editNumberFunc(item) {
      this.editNumberPop = true;
      this.editForm.id = item.id;
      this.editForm.trackNumber = item.tracking_number;
    },
    delIt(item) {
      this.delPop = true;
      this.curItem = item;
    },
    delConfirm() {
      this.confLoading = true;
      _removeSourcing({
        id: this.curItem.id
      })
        .then(() => {
          this.delPop = false;
          this.confLoading = false;
          this.$Message.success("Delete Success");
          this.getSamples(); // refresh
        })
        .catch(({ message }) => {
          this.confLoading = false;
          this.$Message.error(message);
        });
    },
    addIt() {
      this.showInquire = true;
      this.inquireForm.product_name = "";
    },
    editIt(item) {
      this.showInquire = true;
      this.curItem = item;
      this.inquireForm.product_name = item.product_name;
      this.inquireForm.product_type = item.product_type;
      this.inquireForm.country = item.country;
      this.inquireForm.state = item.state;
      this.inquireForm.industry_usage_grade = item.industry_usage_grade;
      this.inquireForm.compendial_standard = item.compendial_standard;

      // industry_usage_grade
      var copyIndustry = JSON.parse(
        JSON.stringify([
          "Food Grade",
          "Feed Grade",
          "Industrial Grade",
          "Cosmetic Grade"
        ])
      );
      if (item.industry_usage_grade) {
        var i = 0;
        item.industry_usage_grade.forEach(ite => {
          if (copyIndustry.indexOf(ite) < 0) {
            i++;
            copyIndustry.push(ite);
            this.industry_usage_grade = copyIndustry;
          }

          if (i === 0) {
            this.industry_usage_grade = copyIndustry;
          }
        });
      } else {
        this.industry_usage_grade = copyIndustry;
      }

      // compendial_standard
      var copyCompendial = JSON.parse(
        JSON.stringify(["USP", "FCC", "JP", "CP"])
      );
      if (item.compendial_standard) {
        var j = 0;
        item.compendial_standard.forEach(ite => {
          if (copyCompendial.indexOf(ite) < 0) {
            j++;
            copyCompendial.push(ite);
            this.compendial_standard = copyCompendial;
          }

          if (j === 0) {
            this.compendial_standard = copyCompendial;
          }
        });
      } else {
        this.compendial_standard = copyCompendial;
      }
    },
    addCheckBox(key) {
      const value = trim(this[`${key}_temp`]);
      if (value) {
        const arr = uniq([...this[key], value]);
        this[key] = arr;
        this.industry_usage_grade_temp = "";
        this.compendial_standard_temp = "";
      }
    },
    sendInquire() {
      if (!this.inquireForm.product_name) {
        this.$Message.warning("Please fill product name");
        return;
      }
      this.confLoading = true;
      _checkSample({ id: this.curItem.id, ...this.inquireForm })
        .then(() => {
          this.$Message.success("Edit Sourcing Success");
          this.getSamples();
          this.showInquire = false;
          this.handleReset("form");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.confLoading = false;
        });
    },
    cancel() {
      this.loading = false;
      this.handleReset("form");
      this.industry_usage_grade_temp = "";
      this.compendial_standard_temp = "";
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    }
  },
  filters: {
    descFilter(val) {
      if (val.length > 20) return val.substring(0, 20) + "...";
      else return val;
    },
    shortTime(val) {
      if (val.length > 10) return val.substring(0, 10);
      else return val;
    }
  }
};
</script>

<style lang="less" scoped>
.sc-width {
  margin: 0 auto;
}
.main-title-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .clearbtn2 {
    cursor: pointer;
    margin-left: 190px;
    padding: 2px 20px;
    color: #fff;
    line-height: 30px;
    background-color: @primary-color;
    border: 1px solid @primary-color;
    border-radius: 6px;
  }
  .extra {
    .total {
      color: #333;
      .des {
        font-size: 16px;
      }
      .num {
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}

.sourcing {
  .ivu-form-item {
    margin-bottom: 15px;
  }
  .checkbox-box-input {
    width: initial;
    .ivu-input {
      border: 1px solid #ccc !important;
    }
  }
  .form-box-title {
    text-align: right;
    vertical-align: middle;
    float: left;
    font-size: 14px;
    color: #515a6e;
    line-height: 1;
    padding: 10px 12px 10px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .compose {
    display: inline-block;
    width: 100%;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 6px;
  }
  .form-title-xing {
    justify-content: initial !important;
  }
  .form-title-xing:before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-size: 14px;
    color: #ed4014;
  }
}

/deep/ .ivu-table-wrapper {
  overflow: inherit;
}

.search {
  background: #fff;
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ccc;
  .search-input {
    width: 400px;
  }
}

.box {
  margin-top: 128px;
  .empty {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards {
    min-height: 300px;
    position: relative;
    .list-table {
      list-style: none;
      li {
        margin-bottom: 10px;
        background-color: #fff;
        border: 1px solid #f2f2f2;
        box-shadow: 0 0 4px #f8f8f8;
        .header {
          padding: 10px;
          border-bottom: 1px solid #f2f2f2;
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          .wrap1 {
            min-width: 80%;
            cursor: pointer;
            text-align: left;
            .title {
              font-size: 14px;
            }
          }
          .wrap2 {
            text-align: right;
            .status {
              display: inline-block;
              background-color: #ddd;
              padding: 2px 8px;
              margin-bottom: 4px;
              &.pend {
                background-color: #ddd;
                color: #333;
              }
              &.acc {
                background-color: #d5ffd2;
                color: #418d3a;
              }
              &.rej {
                background-color: #ffddd9;
                color: #c04c3a;
              }
            }
            .time {
              color: #888;
            }
          }
        }
        .content {
          display: flex;
          padding-bottom: 10px;
          border-bottom: 1px solid #f2f2f2;
          .con {
            padding: 10px;
            .tit {
              color: #666;
              padding-bottom: 6px;
            }
            .tip {
              font-size: 12px;
            }
            .doc {
              color: #ff6600;
              a {
                color: #ff6600;
              }
            }
          }
          .c1 {
            cursor: pointer;
          }
          .c1,
          .c2,
          .c3 {
            flex: 0 0 20%;
          }
          .c4 {
            flex: 0 0 40%;
          }
        }
      }
      .footer {
        padding: 10px;
        text-align: right;
        .numb {
          text-align: left;
          padding: 4px 10px;
          display: inline-block;
          background-color: #ffe7d6;
        }
      }
    }
    .page {
      margin: 10px 0;
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
    }

    .ivu-poptip-body {
      h4 {
        font-size: 12px;
        text-align: left;
        color: #444;
        padding: 4px 0;
      }
      .tags {
        flex-wrap: wrap;
        display: flex;
        p {
          text-align: left;
          color: #999;
          flex: 0 0 50%;
          padding: 4px 0;
        }
      }
    }
  }
}
.list-table {
  .title-top{
    height: 60px;
    background: #fff;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  .title {
    display: flex;
    height: 42px;
    background: #f2f2f2;
    /*border-top-right-radius: 15px;*/
    /*border-top-left-radius: 15px;*/
    justify-content: space-between;
    font-size: 18px;

    font-weight: 500;
    color: #757575;
    line-height: 19px;
    .item {
      padding: 12px 0 0 20px;
    }
    .product {
      width: 400px;
    }
    .attendee {
      width: 300px;
    }
    .size {
      width: 300px;
    }
    .date {
      width: 200px;
    }
    .document {
      width: 300px;
    }
  }
  .content {
    /*display: flex;*/
    .item {
      height: 140px;
      border-bottom: 1px solid #ececec;
      /*padding:0 30px;*/
      .top {
        display: flex;
        height: 80px;
        justify-content: space-between;
        padding-top: 30px;
        div {
          font-size: 16px;

          font-weight: 500;
          color: #323538;
          line-height: 19px;
          padding-left: 20px;
        }
        .product {
          width: 400px;
          .main-name {
          }
          .by-name {
            font-size: 12px;

            font-weight: 400;
            color: #999999;
            line-height: 19px;
          }
        }
        .attendee {
          width: 300px;
        }
        .size {
          width: 300px;
        }
        .date {
          width: 200px;
        }
        .document {
          width: 300px;
        }
      }
      .divided {
        width: 1440px;
        /*height: 1px;*/
        background: #cccccc;
      }
      .bottom {
        display: flex;
        justify-content: space-between;
        /*margin-top: 20px;*/
      }
    }
    .active {
      box-shadow: 0 2px 16px grey, 0 0 1px grey, 0 0 1px grey;
    }
  }
}
.status {
  /*display: inline-block;*/
  /*background-color: #ddd;*/
  /*padding: 2px 8px;*/
  /*margin-bottom: 4px;*/
  padding: 6px 0 0 20px;
  &.pend {
    width: 190px;
    height: 30px;
    background: #e6e6e6;
    border-radius: 5px;
    font-size: 14px;

    font-weight: 400;
    color: #323538;
    line-height: 18px;
  }
  &.acc {
    width: 190px;
    height: 30px;
    background: #e6f7d3;
    border-radius: 5px;
    font-size: 16px;

    font-weight: 400;
    color: #323538;
    line-height: 18px;
  }
  &.rej {
    width: 190px;
    height: 30px;
    background: #f7d3d3;
    border-radius: 5px;
    /*background-color: #ffddd9;*/
    color: #c04c3a;
    font-size: 16px;

    font-weight: 400;
    color: #323538;
    line-height: 18px;
  }
}
.browse {
  cursor: pointer;
  color: #ef842d;
  padding-right: 20px;
}
  .cards-p{
    background: #fff;position: relative;top: 45px;border-radius: 15px;
    padding: 0;
  }
</style>
